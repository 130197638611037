import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation

const Footer: React.FC = () => {
  const [opacity, setOpacity] = useState(0);
  const location = useLocation(); // Get current location object

  useEffect(() => {
    // Reset opacity to 0 whenever the route changes
    setOpacity(0);

    const handleScroll = () => {
      const maxDocumentHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      const calculatedOpacity = scrollPosition / maxDocumentHeight;
      setOpacity(calculatedOpacity);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]); // Add location to dependency array

  return (
    <footer
      className="w-full bg-blue-500 text-white text-sm md:text-base lg:text-lg text-center p-2 md:p-4 fixed inset-x-0 bottom-0"
      style={{ opacity: Math.min(opacity, 1) }}
    >
      <p>© {new Date().getFullYear()} Elie Mubarka. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
