import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Social from "./components/Social";
import ScrollToTop from "./components/ScrollToTop";

import HomePage from "./Home";
import AboutPage from "./About";
import Projects from "./Projects";
import Experience from "./Experience";
// import Contact from "./Contact";

const AppContent = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    // Update the current path only if it's different from the last path
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
  }, [location.pathname, currentPath]);

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center">
      <div className="w-full max-w-5xl bg-white py-20">
        <TransitionGroup>
          <CSSTransition
            key={currentPath} // Use currentPath as key to prevent unnecessary re-renders
            timeout={300}
            classNames="page"
            unmountOnExit // Ensures the component unmounts after transition
          >
            <Routes location={location}>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/experience" element={<Experience />} />
              {/* <Route path="/contact" element={<Contact />} /> */}
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Social />
      <AppContent />
      <Footer />
    </Router>
  );
}

export default App;
