import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import TechTag from "./components/TechTag";

const ProjectCard: React.FC<{
  project: string;
  content: string;
  logoSrc: string;
  technologies: string[];
  link?: string;
}> = ({ project, content, logoSrc, technologies, link }) => {
  const tagContainerMinHeight = "h-auto sm:h-12";
  return (
    <div className="flex flex-col items-center p-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto shadow-lg rounded-lg bg-white">
      <img
        src={logoSrc}
        alt={`${project} Logo`}
        className="h-20 w-20 object-contain mb-4"
      />
      {/* Container for tags with fixed height */}
      <div className={`w-full mt-2  mb-4 ${tagContainerMinHeight}`}>
        <div className="flex flex-wrap justify-center items-start">
          {technologies.map((tech, index) => (
            <TechTag key={index} tech={tech} />
          ))}
        </div>
      </div>
      {/* <div className="font-bold text-xl pt-2 h-12 flex items-center justify-center">
        {project}
      </div> */}
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-xl mb-2 text-center text-blue-500 hover:underline"
        >
          {project}
        </a>
      ) : (
        <h3 className="font-bold text-xl mb-2 text-center">{project}</h3>
      )}
      {/* <div className="text-sm pt-2 text-gray-500 mt-2 flex items-center justify-center">
        {content}
      </div> */}
      <p className="text-sm text-gray-500 text-center px-4">{content}</p>
    </div>
  );
};

const Projects = () => {
  const [showProjects, setShowProjects] = useState(false);
  const [scrollY, setScrollY] = useState(1); // Initialize titleScale state

  // Handle the scroll event and adjust title scaling
  const handleScroll = () => {
    const newScrollY = window.scrollY;
    const scaleRate = 125; // Adjust this number to control how quickly the scale changes
    const minScale = 0; // Minimum scale size
    const newScale = Math.max(1 - newScrollY / scaleRate, minScale);
    setScrollY(newScale);
  };

  // Add scroll event listener in useEffect
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setShowProjects(true); // This triggers the animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Spring animation for title scaling
  const titleAnimation = useSpring({
    opacity: showProjects ? 1 : 0,
    transform: `translateY(${showProjects ? 0 : -30}px) scale(${scrollY})`,
    config: { duration: 150 },
    delay: 150,
  });

  // Define the spring animation for the intro text
  const introAnimation = useSpring({
    opacity: showProjects ? 1 : 0,
    transform: showProjects ? "translateY(0)" : "translateY(-50px)",
    config: { tension: 120, friction: 14 },
    delay: 100,
  });

  // Define the spring for the grid of projects
  const projectsAnimation = useSpring({
    opacity: showProjects ? 1 : 0,
    transform: showProjects ? "translateY(0)" : "translateY(50px)",
    config: { tension: 120, friction: 14 },
    delay: 300, // This delays the start of the animation to stagger the effect
  });

  const projects = [
    {
      project: "Dusty Elements",
      content: `A particle game inspired by the famous game, "Dan-Ball Dust". Created by a partner and I, in attempts to improve our skill in HTML, CSS, and Javascript while recreating a game we really enjoyed when we were young.`,
      logoSrc: "/images/favicon.ico",
      technologies: ["JavaScript", "HTML", "CSS", "AWS"],
      link: "https://dusty-elements.com/",
    },
    {
      project: "HexChess",
      content: `This project was by far my favorite, created with a friend, HexChess is a game we developed, inspired by Gliński's variant. On this project, I created many of the frontend components, but primarily created a hexchess AI using minimax. This was a blast as I got to learn so much, and just think about it, Hexchess AI!`,
      logoSrc: "/images/Hexchess.webp",
      technologies: ["React Native", "TypeScript", "Firebase", "Figma"],
    },
  ];

  return (
    <div className="min-h-[calc(100vh+1rem)] pt-12 w-full">
      <animated.div style={titleAnimation}>
        <h1 className="text-3xl font-bold text-center">Projects</h1>
      </animated.div>
      <animated.div style={introAnimation}>
        <p className="pt-4 text-gray-500 px-16 md:px-20 lg:px-32">
          I have worked many projects in the past, and these are the ones I
          currently have published as of now. I really like to collaborate with
          other programmers for fun ideas, and very open to feedback on my past
          projects. Let me know if you would like to collaborate on a project
          through my socials!
        </p>
      </animated.div>
      <animated.div
        style={projectsAnimation}
        className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-16 md:gap-6 lg:gap-8 px-4 md:px-12 lg:px-24"
      >
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            project={project.project}
            content={project.content}
            logoSrc={project.logoSrc}
            technologies={project.technologies}
            link={project.link}
          />
        ))}
      </animated.div>
    </div>
  );
};

export default Projects;
