import React, { useState } from "react";
import { SocialIcon } from "react-social-icons";

const Social: React.FC = () => {
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [fadeAlert, setFadeAlert] = useState(false);

  const copyEmailToClipboard = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      // Show the alert immediately with fade-in effect
      setShowCopyAlert(true);
      setFadeAlert(false); // Reset fade out

      // Start fading out after 2 seconds (1s for fade-in, 1s display time)
      setTimeout(() => {
        setFadeAlert(true);
      }, 2000);

      // Hide the alert completely after 4.5 seconds (1s fade-in + 2.5s fade-out)
      setTimeout(() => {
        setShowCopyAlert(false);
        setFadeAlert(false); // Reset fade out for the next copy action
      }, 4500);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };
  return (
    <>
      <div className="opacity-50 md:opacity-100 fixed top-1/2 left-0 transform -translate-y-1/2 z-10 p-4">
        <div className="flex flex-col items-center space-y-4">
          {/* LinkedIn */}
          <SocialIcon
            url="https://linkedin.com/in/emubarka"
            bgColor="#0762C8"
            target="_blank"
          />
          {/* GitHub */}
          <SocialIcon
            url="https://github.com/emubarka"
            bgColor="#000000"
            target="_blank"
          />
          {/* Email */}
          <SocialIcon
            network="email"
            bgColor="#D44638"
            onClick={() => copyEmailToClipboard("emubarka@yahoo.com")}
          />
        </div>
      </div>
      {showCopyAlert && (
        <div
          className={`z-10 fixed bottom-0 inset-x-0 flex justify-center px-4 py-2 bg-gray-800 text-white ${
            fadeAlert ? "opacity-0" : "opacity-100"
          }`}
          style={{
            transition: "opacity 1s ease-in-out",
          }}
        >
          Copied to clipboard
        </div>
      )}
    </>
  );
};

export default Social;
