import React from "react";

type TechTagProps = {
  tech: string;
};

const TechTag: React.FC<TechTagProps> = ({ tech }) => {
  return (
    <span className="inline-block bg-blue-200 text-blue-800 text-xs font-semibold mx-1 my-1 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
      {tech}
    </span>
  );
};

export default TechTag;
