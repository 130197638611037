import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Navbar: React.FC = () => {
  const [bgOpacity, setBgOpacity] = useState(0); // Start with a transparent background
  const activeClasses = "text-green-600 font-semibold"; // Active link classes
  const inactiveClasses = "text-black hover:text-gray-700 font-semibold"; // Inactive link classes

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const startTransition = 25; // The scroll position where the transition starts
    const transitionLength = 75; // The length over which the transition occurs

    // Compute opacity. Starts as transparent (0), transitions to solid (1) as you scroll
    const opacity =
      scrollY > startTransition
        ? Math.min((scrollY - startTransition) / transitionLength, 1)
        : 0;

    setBgOpacity(opacity);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50">
      <div className="flex justify-center mt-5">
        <div
          className="flex flex-col items-center shadow-lg rounded-3xl p-3 transition-opacity duration-500 ease-in-out"
          style={{ backgroundColor: `rgba(255, 255, 255, ${bgOpacity})` }} // Dynamic background opacity
        >
          <div className="flex flex-row justify-center space-x-3">
            {/* NavLink components */}
            <NavLink
              to="/"
              className={({ isActive }) =>
                `${isActive ? activeClasses : inactiveClasses}`
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/projects"
              className={({ isActive }) =>
                isActive ? activeClasses : inactiveClasses
              }
            >
              Projects
            </NavLink>
            <NavLink
              to="/experience"
              className={({ isActive }) =>
                isActive ? activeClasses : inactiveClasses
              }
            >
              Experience
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? activeClasses : inactiveClasses
              }
            >
              About
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
