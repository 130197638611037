import { useState } from "react";
import { useSpring, animated, config } from "react-spring";

const Home = () => {
  // State to track different loading stages
  const [imageLoaded, setImageLoaded] = useState(false);
  const [animateName, setAnimateName] = useState(false);
  const [animateDescription, setAnimateDescription] = useState(false);

  // LATER!
  // Hover change for description *i set it up for you chatgpt ;)*
  // const [ishover, setisHover] = useState(false);

  // const handleOnMouseOver = () => {
  //   setisHover(true);
  // };

  // const handleMouseOut = () => {
  //   setisHover(false);
  // };

  // Animation for the image
  const imageAnimation = useSpring({
    opacity: imageLoaded ? 1 : 0,
    from: { opacity: 0 },
    config: { ...config.default, duration: 850 },
  });

  // Animation for the name
  const nameAnimation = useSpring({
    opacity: animateName ? 1 : 0,
    from: { opacity: 0 },
    config: { ...config.default, duration: 850 },
    delay: 150,
  });

  // Animation for the description
  const descriptionAnimation = useSpring({
    opacity: animateDescription ? 1 : 0,
    from: { opacity: 0 },
    config: { ...config.default, duration: 850 },
    delay: 300, // Start after the name has appeared
  });

  // Animation for the description
  const paragraphAnimation = useSpring({
    opacity: animateDescription ? 1 : 0,
    from: { opacity: 0 },
    config: { ...config.default, duration: 850 },
    delay: 450, // Start after the name has appeared
  });

  // Handle image load event
  const handleImageLoaded = () => {
    setImageLoaded(true);
    setTimeout(() => setAnimateName(true), 150); // Delay the name animation
    setTimeout(() => setAnimateDescription(true), 300); // Delay the description animation
  };

  return (
    <div className="min-h-[calc(100vh+1rem)] px-24 pt-12">
      <animated.h1
        style={nameAnimation}
        className="text-6xl font-bold leading-none flex justify-center text-center"
      >
        Elie Mubarka
      </animated.h1>
      <div className="pt-12 grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
        <animated.img
          style={imageAnimation}
          src="/images/Elie.webp"
          alt="Elie Mubarka"
          className="rounded-full shadow-lg hover:shadow-2xl"
          onLoad={handleImageLoaded}
        />

        <div>
          <animated.h2
            style={descriptionAnimation}
            className="text-xl text-gray-800"
          >
            Innovative Software Developer, Web Design Enthusiast, and Cashier
            Veteran
          </animated.h2>
          {/* <animated.h2
            style={descriptionAnimation}
            onMouseEnter={handleOnMouseOver}
            onMouseLeave={handleMouseOut}
            className={`text-xl text-gray-800 ${
              ishover ? "glitter-effect" : ""
            }`}
          >
            Innovative Software Developer, Web Design Enthusiast, and Cashier
            Veteran
          </animated.h2> */}
          <animated.p style={paragraphAnimation} className="mt-4 text-gray-600">
            A Frontend Developer with two years of professional experience with
            background in database management, specifically with MySQL for over
            a year. My expertise is mainly in building web applications
            utilizing various different types of frameworks and technologies. I
            am very passionate about continuously challenging myself and growing
            my skillset as a developer through new opportunities.
          </animated.p>
        </div>
      </div>
    </div>
  );
};

export default Home;
