import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import TechTag from "./components/TechTag";

const ExperienceCard: React.FC<{
  project: string;
  content: string;
  logoSrc: string;
  technologies: string[];
  link?: string; // Optional link parameter
}> = ({ project, content, logoSrc, technologies, link }) => {
  const tagContainerMinHeight = "h-auto sm:h-16";

  return (
    <div className="flex flex-col items-center p-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto shadow-lg rounded-lg bg-white">
      <img
        src={logoSrc}
        alt={`${project} Logo`}
        className="h-20 w-20 object-contain mb-4"
      />
      <div className={`w-full mt-2 mb-4 ${tagContainerMinHeight}`}>
        <div className="flex flex-wrap justify-center items-start">
          {technologies.map((tech, index) => (
            <TechTag key={index} tech={tech} />
          ))}
        </div>
      </div>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-xl mb-2 text-center text-blue-500 hover:underline"
        >
          {project}
        </a>
      ) : (
        <h3 className="font-bold text-xl mb-2 text-center">{project}</h3>
      )}
      <p className="text-sm text-gray-500 text-center px-4">{content}</p>
    </div>
  );
};

const Experience = () => {
  const [showExperience, setShowExperience] = useState(false);
  const [scrollY, setScrollY] = useState(1);

  const handleScroll = () => {
    const newScrollY = window.scrollY;
    const scaleRate = 125;
    const minScale = 0;
    const newScale = Math.max(1 - newScrollY / scaleRate, minScale);
    setScrollY(newScale);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setShowExperience(true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const titleAnimation = useSpring({
    opacity: showExperience ? 1 : 0,
    transform: `translateY(${showExperience ? 0 : -30}px) scale(${scrollY})`,
    config: { duration: 150 },
    delay: 150,
  });

  const introAnimation = useSpring({
    opacity: showExperience ? 1 : 0,
    transform: showExperience ? "translateY(0)" : "translateY(-50px)",
    config: { tension: 120, friction: 14 },
    delay: 100,
  });

  const gridAnimation = useSpring({
    opacity: showExperience ? 1 : 0,
    transform: showExperience ? "translateY(0)" : "translateY(30px)",
    config: { tension: 120, friction: 14 },
    delay: 300,
  });

  const experience = [
    {
      project: "Cooledtured - Web Developer Intern",
      content: `As a Web Developer at Cooledtured, I enhanced their online shopping experience by leading the development of some of the user-centric features while creating dynamic animations as important features for a better user experience.`,
      logoSrc: "/images/Cooledtured.webp",
      technologies: ["Hydrogen", "React", "TypeScript", "Figma", "Tailwind"],
      link: "https://cooledtured.com",
    },
    {
      project: "SDSC - Data Infrastructure Intern",
      content: `During my time as a Data Infrastructure Intern, I handled advanced data for linking the national agriculture trends for the NOURISH Project, as working on the Quantum Data Hub project using relational graphs for connecting science techniques for scientists.`,
      logoSrc: "/images/SDSC.webp",
      technologies: ["Puppeteer", "JavaScript", "MySQL", "Neo4j"],
    },
    {
      project: "SDSC - Software Engineer Intern",
      content: `As a Software Engineer Intern, I led a team for developing the SDSC Internship site. This site was my first time launching a live site to the public, and it's one I am very fond of!`,
      logoSrc: "/images/SDSC.webp",
      technologies: ["React Web", "JavaScript", "Figma"],
      link: "https://interns.sdsc.edu/",
    },
    {
      project: "SDSC - Frontend Developer Intern",
      content: `For my first internship, I was the lead frontend developer on a team of 7 in the creation of a roommate finding app for college students. This was a really memorable project with an amazing group of developers that helped me learn more about Figma prototypes and molding it into a usable frontend. In this project, I learned how to use APIs, real-time messaging, and even app deployment!`,
      logoSrc: "/images/SDSC.webp",
      technologies: [
        "React Native",
        "JavaScript",
        "MySQL",
        "Firebase",
        "Figma",
      ],
    },
  ];

  return (
    <div className="min-h-[calc(100vh+1rem)] pt-12 w-full">
      <animated.div style={titleAnimation}>
        <h1 className="text-3xl font-bold text-center">Experience</h1>
      </animated.div>
      <animated.div style={introAnimation}>
        <p className="pt-4 text-gray-500 px-16 md:px-20 lg:px-32">
          During my time at University of California, San Diego, I have held
          multiple positions in the San Diego Supercomputer Center, and even got
          the privilege to be on the Cooledtured team as a web developer! These
          experiences have really helped me hone in my skills in frontend
          development, as I crafted intuitive websites and apps. At the same
          time, these roles have provided me with very valuable backend
          experience that has allowed me to start tackling on projects that
          involved server-side and database development.
        </p>
      </animated.div>
      <animated.div
        style={gridAnimation}
        className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-16 md:gap-6 lg:gap-8 sm:px-16 md:px-24 lg:px-32"
      >
        {experience.map((exp, index) => (
          <ExperienceCard
            key={index}
            project={exp.project}
            content={exp.content}
            logoSrc={exp.logoSrc}
            technologies={exp.technologies}
            link={exp.link}
          />
        ))}
      </animated.div>
    </div>
  );
};

export default Experience;
