import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

const About = () => {
  // Update the scale transformation based on scroll
  const handleScroll = () => {
    const newScrollY = window.scrollY;
    const scaleRate = 100; // Change this number to adjust the rate of scaling
    const minScale = 0; // Change this number to adjust the minimum scale factor

    // Calculate the new scale based on scroll position
    const newScale = Math.max(1 - newScrollY / scaleRate, minScale);

    // Update state with the new scale
    setTitleScale(newScale);
  };

  // Update your state initialization
  const [titleScale, setTitleScale] = useState(1); // Starts at full scale

  // Add the event listener in the useEffect
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Update the title animation to use the new titleScale state
  const titleAnimation = useSpring({
    to: {
      transform: `scale(${titleScale})`,
    },
  });

  // Animation for the text content
  const textAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
    config: { duration: 500 },
    delay: 100,
  });

  return (
    <div className="min-h-[calc(100vh+1rem)] pt-12 px-24 w-full flex flex-col items-center">
      <animated.div style={titleAnimation} className="w-full max-w-4xl">
        <h1 className="text-3xl lg:text-4xl font-bold text-center mb-6">
          Hi! A little about myself!
        </h1>
      </animated.div>
      <animated.div style={textAnimation} className="w-full max-w-4xl">
        <div className="pt-4 text-gray-500 space-y-4">
          <p>
            My programming journey began at the age of 16, but was fully ignited
            when I started at Butte Community College, embarking on an amazing
            adventure through college! One of my first internships, which was
            aligned with a NASA program aimed at inspiring STEM passion, put me
            as the lead of a team to create a collision-avoidance drone. This
            was my first stepping stone in a coding project that also helped me
            understand the importance of organization for tasks among
            programmers.
          </p>
          <p>
            During my time at UCSD, I got an internship at the San Diego
            Supercomputer Center where I received various types of positions
            such as data infrastructure for projects like the NOURISH project,
            frontend development for creating a roommate finding app, and even
            leading an internship website! Once I was done with the SDSC's
            internships, I moved onto the Cooledtured website, where I was led
            by a professional front-end developer with a team of 10 people that
            really helped expand my frontend skillset.
          </p>
          <p>
            As of now, I am a fresh graduate in Spring 2024, and I am eager to
            bring the experiences I have gained over the years as well as
            excitement for Software Development and expanding it! For the time
            being, I am working on projects and helping out with my family's
            business, so feel free to send me a message if you believe I am the
            right fit for your company!
          </p>
        </div>
      </animated.div>
    </div>
  );
};

export default About;
